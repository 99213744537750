import { cn } from '#app/utils/misc'

function Skeleton({
	className,
	...props
}: React.HTMLAttributes<HTMLDivElement>) {
	return (
		<div
		className={cn('animate-pulse-bg rounded-md bg-primary/10', className)}
		{...props}
		/>
	)
}

export { Skeleton }
